@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color: #f9f9f9;
}

.group:hover .group-hover\:text-primary {
    fill: #ff6347;
}

/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #FDB315;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
}

/* For Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #FDB315 #f1f1f1;
}
.cursor-pointer-success {
    position: relative;
}

.check-circle-icon {
    position: absolute;
    right: 0px;
    top: 0px;
    /* background-color: mediumseagreen; */
    /* font-size: 3rem; */
    border-radius: 100px;
    color: mediumseagreen !important;
}

.cancel-icon {
    position: absolute;
    right: 0px;
    top: 0px;
    /* background-color: mediumseagreen; */
    /* font-size: 3rem; */
    border-radius: 100px;
    color: red !important;
}

.MuiSvgIcon-root {
    font-size: 2.5rem !important;
}
