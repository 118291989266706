.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
    z-index: 11000 !important;
}

div:where(.swal2-container) .swal2-html-container {
    visibility: hidden;
    height: 0;
    margin: 0;
}
